export enum UserRole {
  User = 0,
  Admin = 1,
}

export type UserProfile = {
  id: string
  payload: {
    name: string
    email: string
    phone: string
    company?: string
    position?: string
  }
}

export type User = {
  id: string
  name: string
  email: string
  avatar_url: string
  role: UserRole
  created_at: string
  deleted_at: string | null
  profile: UserProfile | null
}
