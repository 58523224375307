import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import env, { Environment } from "./lib/env"
import "./assets/css/index.css"

if (env.ENVIRONMENT !== Environment.Prod) {
  console.log("starting the app", {
    app: env.APP_NAME,
    version: env.VERSION,
    environment: env.ENVIRONMENT,
  })
}

ReactDOM.render(<App />, document.getElementById("root"))
