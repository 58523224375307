const ALIAS: string = "pagination_page_size"

function getPageSize(): number {
  const value: string | null = localStorage.getItem(ALIAS)
  return value ? parseInt(value) : 10
}

function setPageSize(value: number): void {
  localStorage.setItem(ALIAS, value.toString())
}

export { setPageSize, getPageSize }
