import env from "../../lib/env"
import { Box } from "@material-ui/core"

export default function AppInfo(): JSX.Element {
  return (
    <Box
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        padding: "0px 5px",
        boxShadow: "0 0 5px #000",
        backgroundColor: "#424242",
        fontSize: 10,
      }}
    >
      <span>{env.ENVIRONMENT}</span> / <span>{env.VERSION}</span>
    </Box>
  )
}
