import React from "react"
import { TextFieldProps, TextField } from "@material-ui/core"
import useValidate from "../../hooks/useValidate"

export type FormStoreValue = FormDataEntryValue | null | number
export type FormStore = {
  [key: string]: FormStoreValue
}

export type FormFieldProps = TextFieldProps & {
  checkbox?: boolean
}

type Props = {
  fields: FormFieldProps[]
  onSubmit: (valid: boolean, store: FormStore) => void
}

export default function Form(props: React.PropsWithChildren<Props>): JSX.Element {
  const { fields, onSubmit, children } = props

  const [errors, refForm, validate] = useValidate(fields)

  const submit = (e: React.FormEvent<HTMLFormElement>): boolean => {
    e.preventDefault()
    e.stopPropagation()

    if (!refForm.current) {
      onSubmit(false, {})
      return false
    }

    const store: FormStore = {}
    const formData: FormData = new FormData(refForm.current)
    fields.forEach((field: TextFieldProps) => {
      if (!field.name) {
        return
      }

      let value: FormStoreValue = formData.get(field.name)
      if (typeof value === "string") {
        const type: string | undefined = field.type
        switch (type) {
          case "number":
            value = value.length ? parseInt(value) : null
            break
          case "datetime-local":
            value = value.length ? value : null
            break

          default:
            console.log(value)
            break
        }
      }

      store[field.name] = value
    })

    onSubmit(validate(), store)
    return false
  }

  return (
    <form ref={refForm} onSubmit={submit}>
      {fields.map((field: FormFieldProps) => {
        const name: string | undefined = field.name
        if (name) {
          if (errors[name]) {
            field.error = true
            field.helperText = errors[name]
          } else {
            delete field.error
            delete field.helperText
          }

          field.onBlur = (): void => {
            validate(name)
          }
        }

        if (field.select) {
          if (!field.SelectProps) {
            field.SelectProps = {}
          }

          if (name) {
            field.SelectProps.onBlur = (): void => {
              validate(name)
            }
          }

          return (
            <TextField key={field.name} {...field}>
              {field.SelectProps.children}
            </TextField>
          )
        }

        return <TextField key={field.name} {...field} />
      })}
      {children}
    </form>
  )
}
