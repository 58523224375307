import { Feature } from "./../services/billing/feature"

export enum UserFeatureUsageEntityType {
  CrawlReport = "CrawlReport",
}

export type UserFeatureUsage = {
  id: string
  user_id: string
  user_plan_id: string
  feature: Feature
  value: number
  entity_type: UserFeatureUsageEntityType | null
  entity_id: string | null
  created_at: string
  processed: boolean
}
