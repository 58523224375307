import React from "react"
import { GridCellParams, GridColumns, GridRowParams } from "@material-ui/data-grid"
import Table from "../../../components/Table"
import { DocumentNode, gql, useMutation } from "@apollo/client"
import { Backdrop, Button, Fade, Grid, IconButton, Modal, Paper } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import useCreateInvite from "../../../hooks/useCreateInvite"
import env from "../../../lib/env"
import Copyable from "../../../components/Copyable"
import DeleteIcon from "@material-ui/icons/Delete"
import theme from "../../../lib/theme"
import { userColumn } from "../../../components/Table/columns/userColumn"
import { dateColumn } from "../../../components/Table/columns/dateColumn"

const query: DocumentNode = gql`
  query Invites(
    $page: Int
    $perPage: Int
    $sortField: UserInviteSortFieldGQLType
    $sortOrder: SortOrderGQLType
    $filterCreateBy: String
    $filterUser: String
  ) {
    invites(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filterCreateBy: $filterCreateBy
      filterUser: $filterUser
    ) {
      data {
        id
        created_at
        token
        expires_at
        user {
          name
          avatar_url
        }
        creator {
          name
          avatar_url
        }
      }
      meta {
        pagination {
          total_count
          page
          per_page
          last_page
        }
      }
    }
  }
`

const DELETE_INVITE: DocumentNode = gql`
  mutation DeleteInvite($id: UUID!) {
    deleteInvite(id: $id) {
      id
      created_at
      token
      expires_at
      user {
        name
        avatar_url
      }
      creator {
        name
        avatar_url
      }
    }
  }
`

function InvitesPage(): JSX.Element {
  const [updateTable, setUpdateTable] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [createInvite, { isLoading, data }] = useCreateInvite()

  const [fetchDeleteInvite] = useMutation(DELETE_INVITE)

  React.useEffect(() => {
    if (updateTable) {
      setUpdateTable(false)
    }
  }, [updateTable])

  React.useEffect(() => {
    if (data !== null) {
      setUpdateTable(true)
      setShowModal(true)
    }
  }, [data])

  const onCloseModal = (): void => {
    setShowModal(false)
  }

  const deleteInvite = async (id: string): Promise<void> => {
    await fetchDeleteInvite({
      variables: {
        id,
      },
    })
  }

  const columns: GridColumns = [
    userColumn({
      key: "creator",
      field: "created_by",
      headerName: "created_by",
      filterable: true,
      type: "search",
    }),
    dateColumn({
      field: "created_at",
      headerName: "created_at",
    }),
    dateColumn({
      field: "expires_at",
      headerName: "expires_at",
    }),
    userColumn({
      key: "user",
      field: "user_id",
      headerName: "user_id",
      filterable: true,
      type: "search",
    }),
    {
      flex: 2,
      field: "link",
      filterable: false,
      headerName: "link",
      sortable: false,
      renderCell: (params: GridCellParams): JSX.Element => {
        const expiresAt: number = parseInt(params.row.expires_at as string)
        if (params.row.user !== null || expiresAt <= Date.now()) {
          return <></>
        }

        const link: string = `${env.CABINET_URL}login?invite=${params.row.token}`
        return <Copyable value={link} />
      },
    },
    {
      field: "actions",
      filterable: false,
      headerName: " ",
      sortable: false,
      renderCell: (params: GridCellParams): JSX.Element => (
        <IconButton
          onClick={(): Promise<void> => deleteInvite(params.row.id as string)}
          disabled={params.row.user !== null || parseInt(params.row.expires_at as string) <= Date.now()}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ]

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <Button onClick={createInvite} disabled={isLoading} startIcon={<Add />}>
            Create
          </Button>
        </Grid>
        <Grid item sm={12}>
          <Table
            columns={columns}
            query={query}
            sortModel={[
              {
                field: "created_at",
                sort: "desc",
              },
            ]}
            fieldsForVariables={{
              created_by: "filterCreateBy",
              user_id: "filterUser",
            }}
            getRowClassName={(params: GridRowParams): string =>
              parseInt(params.row.expires_at as string) <= Date.now() ? "row-deleted" : ""
            }
            forceUpdate={updateTable}
          />
        </Grid>
      </Grid>
      <Modal
        open={showModal}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade
          in={showModal}
          style={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            maxWidth: 750,
          }}
        >
          <Paper>
            <h2 id="transition-modal-title">New Invite</h2>
            <p id="transition-modal-description">
              Here's a new invite link. Copy and share it with a future client. Once client follows this link they will
              be able to register with the service.
            </p>
            {data !== null && <Copyable value={data.data.invite_url} />}
          </Paper>
        </Fade>
      </Modal>
    </>
  )
}

export default InvitesPage
