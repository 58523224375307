import { getGridStringOperators, GridFilterInputValueProps, GridFilterOperator } from "@material-ui/data-grid"
import { SelectInputProps } from "@material-ui/core/Select/SelectInput"
import React from "react"
import { FormControl, InputLabel, Select } from "@material-ui/core"

type Data = {
  [key: string]: string
}

export function enumFilterOperators(data: Data): GridFilterOperator[] {
  return getGridStringOperators()
    .filter((operator: GridFilterOperator) => operator.value === "equals")
    .map(
      (operator: GridFilterOperator): GridFilterOperator => ({
        ...operator,
        // @ts-ignore
        InputComponent: SelectInputValue,
        InputComponentProps: {
          data,
        },
      }),
    )
}

function SelectInputValue(props: GridFilterInputValueProps & { data: Data }): JSX.Element {
  const { item, applyValue } = props

  const data: Data = props.data as Data

  const handleFilterChange: SelectInputProps["onChange"] = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    applyValue({ ...item, value: event.target.value as string })
  }

  return (
    <FormControl>
      <InputLabel>Value</InputLabel>
      <Select native value={item.value ?? ""} onChange={handleFilterChange}>
        <option aria-label="None" value="" />
        {Object.entries(data).map(([key, value]: [string, string]) => (
          <option key={value} value={value}>
            {key}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}
