import { match, Route, Switch, useRouteMatch } from "react-router-dom"
import ReportsRouter from "./reports/router"

function ProjectsRouter(): JSX.Element {
  const match: match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.url}/reports`} component={ReportsRouter} />
    </Switch>
  )
}

export default ProjectsRouter
