import { GridCellParams, GridColumns } from "@material-ui/data-grid"
import LinkMUI from "@material-ui/core/Link"
import React from "react"
import Table from "../../components/Table"
import { dateColumn } from "../../components/Table/columns/dateColumn"
import { userColumn } from "../../components/Table/columns/userColumn"
import { USER_FEATURE_USAGE } from "../../hooks/useUserFeatureUsage"
import { UserFeatureUsageEntityType } from "../../types/userFeatureUsage"
import { Link } from "react-router-dom"
import { enumFilterOperators } from "../../components/Table/filterOperators/enumFilterOperators"
import { Feature } from "../../services/billing/feature"

function FeatureUsagePage(): JSX.Element {
  const columns: GridColumns = [
    userColumn({
      key: "user",
      field: "user_id",
      headerName: "user_id",
      filterable: true,
      type: "search",
    }),
    {
      field: "feature",
      sortable: true,
      headerName: "feature",
      width: 150,
      filterable: true,
      filterOperators: enumFilterOperators({
        [Feature.CrawlBudgetTotal]: Feature.CrawlBudgetTotal.toLowerCase(),
        [Feature.CrawlMaxThreadCount]: Feature.CrawlMaxThreadCount.toLowerCase(),
      }),
      renderCell: (params: GridCellParams): JSX.Element => <>{params.row.feature}</>,
    },
    {
      field: "value",
      sortable: true,
      headerName: "value",
      renderCell: (params: GridCellParams): JSX.Element => <>{params.row.value}</>,
    },
    {
      flex: 1,
      field: "entity_type",
      sortable: false,
      headerName: "entity",
      filterable: false,
      renderCell: (params: GridCellParams): JSX.Element => {
        const label: JSX.Element = (
          <>
            {params.row.entity_type} - {params.row.entity_id}
          </>
        )

        if (params.row.entity_type === UserFeatureUsageEntityType.CrawlReport) {
          return (
            <LinkMUI component={Link} to={`/projects/reports?id=${params.row.entity_id}`}>
              {label}
            </LinkMUI>
          )
        }
        return label
      },
    },
    dateColumn({
      field: "created_at",
      headerName: "created_at",
    }),
    {
      field: "processed",
      headerName: "processed",
      width: 125,
      align: "center",
      filterable: true,
      type: "boolean",
    },
  ]

  return (
    <Table
      columns={columns}
      query={USER_FEATURE_USAGE}
      sortModel={[
        {
          field: "created_at",
          sort: "desc",
        },
      ]}
      fieldsForVariables={{
        user_id: "filterUser",
        feature: "filterFeature",
        processed: "filterProcessed",
      }}
    />
  )
}

export default FeatureUsagePage
