export enum PaymentSystem {
  Modulbank = "mb",
  Qiwi = "qiwi",
}

export type Order = {
  id: string
  user_id: string
  plan_id: string
  user_plan_id: string | null
  amount: number // price Р * 1000
  status: OrderStatus
  details: OrderDetails
  payload: OrderPayload | null
  created_at: string
  expires_at: string
  processed_at: string | null
}

export type OrderPayload = Record<string, string>

export type OrderDetails = {
  price: number
  auto_renew: boolean
  payment_system: PaymentSystem
  discount?: number
}

export enum OrderStatus {
  New = 0,
  Processing = 1,
  Processed = 2,
  Error = 3,
}
