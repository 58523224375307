import { gql, useMutation } from "@apollo/client"
import { DocumentNode } from "graphql"

const query: DocumentNode = gql`
  mutation DeleteUserPlan($id: UUID!) {
    deleteUserPlan(id: $id) {
      id
      user_id
      plan_id
      start_at
      finish_at
      deleted_at
      plan {
        name
        alias
      }
    }
  }
`

export default function useDeleteUserPlan(): ReturnType<typeof useMutation> {
  return useMutation(query)
}
