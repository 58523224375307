import { DocumentNode, gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client"
import { Order } from "../types/order"
import { Plan } from "../types/plan"
import { User } from "../types/user"

export const LOAD_ORDER: DocumentNode = gql`
  query($id: UUID) {
    order(id: $id) {
      id
      user_id
      plan_id
      user_plan_id
      amount
      status
      details {
        price
        auto_renew
        payment_system
        discount
      }
      created_at
      payload
      expires_at
      processed_at
      user {
        id
        name
        email
        avatar_url
        role
        created_at
        deleted_at
        profile {
          payload {
            name
            email
            phone
            company
            position
          }
        }
      }
      plan {
        id
        name
        alias
        description
        price
        schedule
      }
    }
  }
`

export default function useLoadOrder(
  options: QueryHookOptions,
): QueryResult<{ order: (Order & { user: User; plan: Plan }) | null }> {
  return useQuery(LOAD_ORDER, options)
}
