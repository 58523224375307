import { Box, Typography } from "@material-ui/core"
import React from "react"
import Empty from "../Empty"

export type SectionItem = {
  label: string
  key?: string
  action?: React.ReactNode
  value: React.ReactNode
}

type Props = {
  title: React.ReactNode
  action?: React.ReactNode
  items?: SectionItem[]
  type?: "list" | "flex"
}

export default function Section(props: React.PropsWithChildren<Props>): JSX.Element {
  const { title, action, type = "flex", items = [] } = props

  return (
    <Box className="card-section">
      <Typography className="card-section__title" component="span">
        {title} {action}
      </Typography>
      <Box className="card-section__body">
        <Box className="list-with-flex">
          {!items.length ? (
            <Empty message={`No ${title}`} />
          ) : (
            items.map((item: SectionItem) => (
              <Typography
                key={item.key ? item.key : item.label}
                component="span"
                className={`list-with-flex__item ${type === "list" ? "list-with-flex__item-full" : ""}`}
                color="textSecondary"
              >
                <strong className="list-with-flex__label">{item.label}</strong>:{" "}
                <Typography component="span" className="list-with-flex__value">
                  {item.value}
                </Typography>
                {item.action && <Box className="list-with-flex__action">{item.action}</Box>}
              </Typography>
            ))
          )}
        </Box>
      </Box>
    </Box>
  )
}
