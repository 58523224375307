import { DocumentNode, gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client"
import { User } from "../types/user"

export const LOAD_USER: DocumentNode = gql`
  query($id: UUID) {
    user(id: $id) {
      id
      name
      email
      avatar_url
      role
      created_at
      deleted_at
      profile {
        payload {
          name
          email
          phone
          company
          position
        }
      }
    }
  }
`

export default function useFetchUser(options: QueryHookOptions): QueryResult<{ user: User }> {
  return useQuery(LOAD_USER, options)
}
