import { ApolloClient, HttpLink, InMemoryCache, ServerError } from "@apollo/client"
import env, { Environment } from "../env"
import { ErrorResponse, onError } from "@apollo/client/link/error"
import { ApolloLink } from "@apollo/client/link/core"
import { relayStylePagination } from "@apollo/client/utilities"

const httpLink: HttpLink = new HttpLink({ uri: `${env.API_URL}admin/api/graphql`, credentials: "include" })

const logoutLink: ApolloLink = onError(({ networkError }: ErrorResponse) => {
  if (networkError && (networkError as ServerError).statusCode === 401) {
    window.location.href = `${env.CABINET_URL}login`
  }
})

const client: ApolloClient<{}> = new ApolloClient({
  link: logoutLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          users: relayStylePagination(),
          invites: relayStylePagination(),
          feedbackList: relayStylePagination(),
          crawlReportSharedLinks: relayStylePagination(),
          crawlReports: relayStylePagination(),
          plans: relayStylePagination(),
          orders: relayStylePagination(),
          userFeatureUsage: relayStylePagination(),
        },
      },
    },
  }),
  connectToDevTools: env.ENVIRONMENT !== Environment.Prod,
})

export default client
