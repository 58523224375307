import React from "react"
import { GridCellParams, GridColumns, GridOptions, GridRowParams } from "@material-ui/data-grid"
import { User, UserProfile, UserRole } from "../../types/user"
import Table, { FieldsForVariables } from "../../components/Table"
import { userColumn } from "../../components/Table/columns/userColumn"
import { dateColumn } from "../../components/Table/columns/dateColumn"
import { enumFilterOperators } from "../../components/Table/filterOperators/enumFilterOperators"
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core"
import Empty from "../../components/Empty"
import { Add, CloseOutlined, Delete, DoneOutlined } from "@material-ui/icons"
import DateDisplay from "../../components/DateDisplay"
import Form, { FormFieldProps, FormStore } from "../../components/Form"
import UserAvatar from "../../components/UserAvatar"
import useCreateUserPlan from "../../hooks/useCreateUserPlan"
import useDeleteUserPlan from "../../hooks/useDeleteUserPlan"
import useLoadPlans from "../../hooks/useLoadPlans"
import useLoadUserPlans from "../../hooks/useLoadUserPlans"
import removeGraphQLFields from "../../services/utils/removeGraphQLFields"
import { Plan } from "../../types/plan"
import { UserPlan } from "../../types/userPlan"
import Section from "../../components/Section"
import { LOAD_USERS } from "../../hooks/useLoadUsers"
import useFetchUser from "../../hooks/useFetchUser"
import { match, Route, useHistory, useParams, useRouteMatch } from "react-router-dom"

const columns: GridColumns = [
  userColumn({
    field: "name",
    headerName: "name",
    sortable: true,
    filterable: true,
    type: "search",
  }),
  {
    flex: 1,
    field: "email",
    headerName: "email",
    filterable: true,
    type: "search",
    renderCell: (params: GridCellParams): JSX.Element => {
      const profile: UserProfile = params.row.profile
      if (!profile) {
        return <>{params.value}</>
      }

      return <>{profile.payload.email}</>
    },
  },
  dateColumn({
    field: "created_at",
    headerName: "created_at",
  }),
  {
    width: 100,
    field: "role",
    headerName: "role",
    filterOperators: enumFilterOperators({
      [UserRole[UserRole.User]]: UserRole[UserRole.User].toLowerCase(),
      [UserRole[UserRole.Admin]]: UserRole[UserRole.Admin].toLowerCase(),
    }),
    renderCell: (params: GridCellParams): JSX.Element => {
      const role: string = UserRole[params.value as number]
      if (!role) {
        return <>Unknown role ({params.value})</>
      }

      return <>{role}</>
    },
  },
]

const fieldsForVariables: FieldsForVariables = {
  name: "filterName",
  email: "filterEmail",
  role: "filterRole",
}

const sortModel: GridOptions["sortModel"] = [
  {
    field: "created_at",
    sort: "asc",
  },
]

function UsersPage(): JSX.Element {
  const match: match = useRouteMatch()
  const history: ReturnType<typeof useHistory> = useHistory()

  const onRowClick: GridOptions["onRowClick"] = (params: GridRowParams): void => {
    history.push(`${match.url}/${params.row.id}`)
  }

  const getRowClassName = (params: GridRowParams): string => (params.row.updated_at === null ? `row-deleted` : "")

  return (
    <Grid container spacing={1}>
      <Grid item sm={9}>
        <Table
          columns={columns}
          query={LOAD_USERS}
          fieldsForVariables={fieldsForVariables}
          sortModel={sortModel}
          getRowClassName={getRowClassName}
          onRowClick={onRowClick}
        />
      </Grid>
      <Grid item sm={3}>
        <Route path={`${match.url}/:id`} component={EditUser} />
      </Grid>
    </Grid>
  )
}

export default UsersPage

function EditUser(): JSX.Element | null {
  const { id } = useParams<{ id: string }>()

  const { data, loading } = useFetchUser({
    fetchPolicy: "no-cache",
    variables: {
      id,
    },
  })

  if (!id) {
    return <Empty resourceName="report" />
  }

  if (!data || !data.user || loading) {
    return null
  }

  const user: User = data.user
  const profile: UserProfile | null = user.profile

  return (
    <Card>
      <CardHeader
        avatar={<UserAvatar small={false} url={user.avatar_url} />}
        title={profile == null ? user.name : profile.payload.name}
        subheader={<DateDisplay value={user.created_at} />}
      />
      <CardContent>
        <Section
          title="User detail"
          items={removeGraphQLFields(Object.keys(user))
            .filter((key: string) => key !== "profile" && key !== "avatar_url")
            .map((key: string) => {
              let value: React.ReactNode
              switch (key) {
                case "role":
                  value = UserRole[user[key as keyof User] as number]
                  break
                case "created_at":
                case "deleted_at":
                  value = <DateDisplay value={user[key as keyof User] as string | null} />
                  break

                default:
                  value = user[key as keyof User]
                  break
              }

              return {
                label: key,
                value,
              }
            })}
        />
        {profile && (
          <Section
            title="Profile"
            items={removeGraphQLFields(Object.keys(profile.payload)).map((key: string) => ({
              label: key,
              value: profile.payload[key as keyof UserProfile["payload"]],
            }))}
          />
        )}
        <UserPlans id={user.id} />
      </CardContent>
    </Card>
  )
}

type UserPlansProps = {
  id: string
}

function UserPlans(props: React.PropsWithChildren<UserPlansProps>): JSX.Element {
  const { id } = props

  const [showModal, setShowModal] = React.useState(false)

  const [deleUserPlan] = useDeleteUserPlan()

  const { data, refetch } = useLoadUserPlans({
    variables: {
      filterUserId: id,
    },
  })

  const changeShowModal = (): void => {
    setShowModal(true)
  }

  const loadUserPlans = async (): Promise<void> => {
    await refetch()
  }

  const deleteUserPlan = async (userPlan: UserPlan): Promise<void> => {
    await deleUserPlan({
      variables: {
        id: userPlan.id,
      },
    })
    await loadUserPlans()
  }

  React.useEffect(() => {
    loadUserPlans()
  }, [id])

  return (
    <>
      <Section
        title="Plans"
        type="list"
        action={
          <IconButton size="small" onClick={changeShowModal}>
            <Add />
          </IconButton>
        }
        items={data?.userPlans.data.map((userPlan: UserPlan, index: number) => ({
          label: `${userPlan.plan.name} (${userPlan.plan.alias})`,
          key: `${userPlan.plan.name} (${userPlan.plan.alias}) ${index}`,
          action: (
            <IconButton
              onClick={(): void => {
                deleteUserPlan(userPlan)
              }}
              disabled={userPlan.deleted_at !== null}
              size="small"
            >
              <Delete />
            </IconButton>
          ),
          value: (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              From: <DateDisplay value={userPlan.start_at} /> <br /> To:{" "}
              {userPlan.finish_at === null ? (
                "Permanent"
              ) : (
                <>
                  <DateDisplay value={userPlan.finish_at} />{" "}
                  {new Date(userPlan.finish_at).getTime() < Date.now() && (
                    <Typography component="span">(expired)</Typography>
                  )}
                </>
              )}
              <br />
              <Typography component="span" style={{ width: "100%", display: "flex", alignItems: "center" }}>
                Auto renew:{" "}
                {userPlan.auto_renew ? <DoneOutlined fontSize="small" /> : <CloseOutlined fontSize="small" />}
              </Typography>
            </div>
          ),
        }))}
      />
      <CreateUserPlanModal
        userId={id}
        open={showModal}
        onClose={(): void => {
          setShowModal(false)
          loadUserPlans()
        }}
      />
    </>
  )
}

type CreateUserPlanModalProps = {
  userId: string
  open: boolean
  onClose: () => void
}

function CreateUserPlanModal(props: React.PropsWithChildren<CreateUserPlanModalProps>): JSX.Element {
  const { userId, open, onClose } = props

  const [createUserPlan] = useCreateUserPlan()

  const { data } = useLoadPlans()

  const fields: FormFieldProps[] = [
    {
      select: true,
      fullWidth: true,
      label: "Plan",
      margin: "dense",
      name: "planId",
      required: true,
      SelectProps: {
        defaultValue: "",
        children: data?.plans.data.map((plan: Plan) => <MenuItem value={plan.id}>{plan.name}</MenuItem>),
      },
    },
    {
      name: "startAt",
      margin: "dense",
      label: "Start at",
      type: "datetime-local",
      defaultValue: new Date().toISOString().slice(0, -5),
      InputLabelProps: { shrink: true },
      fullWidth: true,
      required: true,
    },
  ]

  const create = async (valid: boolean, store: FormStore): Promise<void> => {
    if (!valid) {
      return
    }

    const input: { [key: string]: number | string } = {
      userId,
    }

    for (const field in store) {
      input[field] = store[field] as string | number
    }

    await createUserPlan({
      variables: {
        input,
      },
    })

    onClose()
  }

  return (
    <Dialog open={open} maxWidth="sm" onClose={(): void => onClose()}>
      <DialogTitle>Create user plan</DialogTitle>
      <DialogContent>
        <Form fields={fields} onSubmit={create}>
          <DialogActions>
            <Button color="primary" onClick={(): void => onClose()}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
