import useFetch, { UseFetch } from "./useFetch"
import { RequestMethod } from "../services/fetchApi"

type CreateInviteResponse = {
  success: boolean
  data: {
    invite_url: string
  }
}

export default function useCreateInvite(): UseFetch<CreateInviteResponse> {
  return useFetch<CreateInviteResponse>(`auth/invite`, {
    method: RequestMethod.Post,
  })
}
