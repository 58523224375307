import { Route, Switch, useRouteMatch, match } from "react-router-dom"
import InvitesPage from "./index"

function InvitesRouter(): JSX.Element {
  const match: match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={match.url} component={InvitesPage} />
    </Switch>
  )
}

export default InvitesRouter
