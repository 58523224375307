import { Route, Switch, useRouteMatch, match } from "react-router-dom"
import FeedbackPage from "./index"

function FeedbackRouter(): JSX.Element {
  const match: match = useRouteMatch()
  return (
    <Switch>
      <Route path={match.url} component={FeedbackPage} />
    </Switch>
  )
}

export default FeedbackRouter
