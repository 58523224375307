import { UserFeatureUsage } from "./../types/userFeatureUsage"
import { gql, QueryHookOptions } from "@apollo/client"
import { DocumentNode } from "graphql"
import useQueryTable, { UseQueryTable } from "./useQueryTable"

export const LOAD_ORDERS: DocumentNode = gql`
  query Orders($page: Int, $perPage: Int) {
    orders(page: $page, perPage: $perPage) {
      data {
        id
        user_id
        plan_id
        user_plan_id
        amount
        status
        details {
          price
          auto_renew
          payment_system
          discount
        }
        created_at
        expires_at
        processed_at
        user {
          id
          name
          email
          avatar_url
          role
          created_at
          deleted_at
          profile {
            payload {
              name
              email
              phone
              company
              position
            }
          }
        }
        plan {
          id
          name
          alias
          description
          price
          schedule
        }
      }
      meta {
        pagination {
          total_count
          page
          per_page
          last_page
        }
      }
    }
  }
`

export default function useLoadOrders(options: QueryHookOptions): UseQueryTable<"userFeatureUsage", UserFeatureUsage> {
  return useQueryTable(LOAD_ORDERS, options)
}
