import { User } from "./user"

export enum CrawlReportStatus {
  New = 1,
  Pending = 2,
  Processing = 5,
  Processed = 3,
  Error = 6,
  Deleted = 4,
}

export type CrawlReportPayloadStatus = {
  budget_used: number
  resources_enqueued: number
  speed: number
  crawl_done: boolean
  done: boolean
  started_at: string
  crawl_finished_at: string
  finished_at: string
  error: string | null
}

export type CrawlReportPayloadSettings = {
  thread_count: number
  thread_sleep_ms: number
  budget: number
  max_dfi: number
  timeout_ms: number
  sitemaps: string[]

  use_robotstxt: boolean
  use_metarobots: boolean
  use_canonical: boolean

  follow_subdomains: boolean
  treat_www_as_same_host: boolean
  follow_nofollow_links: boolean
  fetch_external_links: boolean

  user_agent: string
  robots_user_agent: string
}

export type CrawlReportPayload = {
  settings: CrawlReportPayloadSettings
  status: null | CrawlReportPayloadStatus
  result: null | {
    robotstxt_lines?: string[]
  }
}

export type CrawlReport = {
  id: string
  created_by: string
  project_id: string
  entry_point: string
  budget: number
  budget_used: number
  thread_count: number
  status: CrawlReportStatus
  payload: CrawlReportPayload
  user: User
  created_at: string
  updated_at: string
  deleted_at: string | null
}
