import { GridCellParams, GridColDef } from "@material-ui/data-grid"
import UserAvatar from "../../UserAvatar"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Link } from "@material-ui/core"

type UserColumnCtmParams = GridColDef & {
  key?: string // name key where exist user
}

export function userColumn(options: UserColumnCtmParams): GridColDef {
  return {
    width: 200,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams): JSX.Element => {
      if (!options.key) {
        return <UserAvatar url={params.row.avatar_url} name={<>{params.value as string}</>} />
      }

      if (!params.row[options.key]) {
        return <></>
      }

      const link: JSX.Element = (
        <Link component={RouterLink} to={`/users/${params.row[options.key].id}`}>
          {params.row[options.key].name}
        </Link>
      )
      return <UserAvatar url={params.row[options.key].avatar_url} name={link} />
    },
    ...options,
  }
}
