import { DocumentNode, gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client"
import { CrawlReport } from "../types/crawlReport"

export const LOAD_CRAWL_REPORT: DocumentNode = gql`
  query($id: UUID) {
    crawlReport(id: $id) {
      id
      created_by
      project_id
      entry_point
      budget
      budget_used
      thread_count
      status
      alias
      project {
        alias
      }
      payload {
        settings {
          thread_count
          thread_sleep_ms
          budget
          max_dfi
          timeout_ms
          sitemaps
          use_robotstxt
          use_metarobots
          use_canonical
          follow_subdomains
          treat_www_as_same_host
          follow_nofollow_links
          fetch_external_links
          user_agent
          robots_user_agent
        }
        status {
          budget_used
          resources_enqueued
          speed
          crawl_done
          done
          started_at
          crawl_finished_at
          finished_at
          error
        }
        result {
          robotstxt_lines
        }
      }
      user {
        id
        name
        email
        avatar_url
        role
        created_at
        deleted_at
      }
      created_at
      updated_at
      deleted_at
    }
  }
`

export default function useLoadCrawlReport(
  options: QueryHookOptions,
): QueryResult<{ crawlReport: CrawlReport | null }> {
  return useQuery(LOAD_CRAWL_REPORT, options)
}
