import { TextFieldProps } from "@material-ui/core"
import React from "react"

type UseValidate = [ValidateErrors, React.RefObject<HTMLFormElement>, (...fieldNames: string[]) => boolean]
type ValidateErrors = { [key: string]: string }

export default function useValidate(fields: TextFieldProps[]): UseValidate {
  const refForm: React.RefObject<HTMLFormElement> = React.useRef<HTMLFormElement>(null)

  const [errors, setErrors] = React.useState<ValidateErrors>({})

  const validate: () => boolean = React.useCallback((...fieldNames: string[]): boolean => {
    if (!refForm.current) {
      return false
    }

    let checkFields: TextFieldProps[] = fields
    if (fieldNames.length) {
      checkFields = fields.filter((field: TextFieldProps) => field.name && fieldNames.includes(field.name))
    }

    const formData: FormData = new FormData(refForm.current)
    const errors: ValidateErrors = {}
    checkFields.forEach((field: TextFieldProps) => {
      if (!field.name) {
        return
      }

      const name: string = field.name
      const value: null | FormDataEntryValue = formData.get(field.name)
      if (field.required) {
        if (!value || typeof value !== "string" || !value.length) {
          errors[name] = `${field.label} is required`
          return
        }
      }
    })

    setErrors((prevState: ValidateErrors) => {
      if (!fieldNames.length) {
        return errors
      }

      checkFields.forEach((field: TextFieldProps) => {
        if (!field.name) {
          return
        }

        if (prevState[field.name]) {
          delete prevState[field.name]
        }
      })

      return { ...errors, ...prevState }
    })

    return Object.keys(errors).length === 0
  }, [])

  return [errors, refForm, validate]
}
