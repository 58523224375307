import { Route, Switch, useRouteMatch, match } from "react-router-dom"
import UsersPage from "./index"
import InvitesRouter from "./invites/router"

function UsersRouter(): JSX.Element {
  const match: match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${match.url}/invites`} component={InvitesRouter} />
      <Route path={match.url} component={UsersPage} />
    </Switch>
  )
}

export default UsersRouter
