import { getGridStringOperators, GridColumnTypesRecord, GridFilterOperator } from "@material-ui/data-grid"

const columnTypes: GridColumnTypesRecord = {
  search: {
    type: "string",
    filterOperators: getGridStringOperators().filter((operator: GridFilterOperator) => operator.value === "equals"),
  },
}

export default columnTypes
