import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Location } from "history"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import {
  createStyles,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  StyleRules,
  Theme,
} from "@material-ui/core"
import { ClassNameMap } from "@material-ui/styles/withStyles"
import logo from "./../../../assets/images/white_logo.svg"
import { AuthResponse } from "../../../hooks/useLogin"
import UserMenu from "../../UserMenu"
import theme from "../../../lib/theme"

const drawerWidth: number = 240

type classKey = "content" | "toolbar" | "root" | "drawer" | "appBar" | "drawerPaper"

type useStylesClassMap = ClassNameMap<classKey>

const useStyles: () => useStylesClassMap = makeStyles(
  (theme: Theme): StyleRules<classKey> =>
    createStyles({
      root: {
        display: "flex",
      },
      drawer: {
        [theme.breakpoints.up("sm")]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
      },
      // necessary for content to be below app bar
      toolbar: theme.mixins.toolbar,
      drawerPaper: {
        width: drawerWidth,
        paddingTop: theme.spacing(8),
        backgroundColor: theme.palette.background.default,
        borderRight: "none",
        "& .MuiListItem-button": {
          background: "transparent",
          opacity: ".6",
        },
        "& .Mui-selected": {
          opacity: "1",
        },
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(2),
      },
    }),
)

type Props = {
  auth: AuthResponse
}

function MainLayout(props: React.PropsWithChildren<Props>): JSX.Element {
  const { children, auth } = props
  const classes: useStylesClassMap = useStyles()
  const location: Location = useLocation()

  // use pathname as alias for selected item
  const selectedAlias: string = React.useMemo(() => location.pathname, [location])

  const menu: JSX.Element = (
    <List dense>
      <ListItem button key="/dashboard" component={Link} selected={selectedAlias === "/dashboard"} to="/dashboard">
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem button key="/users" component={Link} selected={selectedAlias.includes("/users")} to="/users">
        <ListItemText primary="Users" />
      </ListItem>
      <List dense component="div" disablePadding>
        <ListItem
          button
          key="/users/invites"
          style={{ paddingLeft: theme.spacing(4) }}
          component={Link}
          selected={selectedAlias === "/users/invites"}
          to="/users/invites"
        >
          <ListItemText primary="Invites" />
        </ListItem>
      </List>
      <ListItem button key="/feedback" component={Link} selected={selectedAlias === "/feedback"} to="/feedback">
        <ListItemText primary="Feedback" />
      </ListItem>
      <ListItem button disabled>
        <ListItemText primary="Crawl Projects" />
      </ListItem>
      <List dense={true} component="div" disablePadding>
        <ListItem
          button
          key="/projects/reports"
          style={{ paddingLeft: theme.spacing(4) }}
          component={Link}
          selected={selectedAlias === "/projects/reports"}
          to="/projects/reports"
        >
          <ListItemText primary="Reports" />
        </ListItem>{" "}
        <ListItem
          button
          key="/projects/reports/shared-links"
          style={{ paddingLeft: theme.spacing(4) }}
          component={Link}
          selected={selectedAlias.includes("/projects/reports/shared-links")}
          to="/projects/reports/shared-links"
        >
          <ListItemText primary="Report shared links" />
        </ListItem>
      </List>
      <ListItem button disabled>
        <ListItemText primary="Billing" />
      </ListItem>
      <List dense={true} component="div" disablePadding>
        <ListItem
          button
          key="/billing/plans"
          style={{ paddingLeft: theme.spacing(4) }}
          component={Link}
          selected={selectedAlias === "/billing/plans"}
          to="/billing/plans"
        >
          <ListItemText primary="Plans" />
        </ListItem>
        <ListItem
          button
          key="/billing/orders"
          style={{ paddingLeft: theme.spacing(4) }}
          component={Link}
          selected={selectedAlias.includes("/billing/orders")}
          to="/billing/orders"
        >
          <ListItemText primary="Orders" />
        </ListItem>
      </List>
      <List dense={true} component="div" disablePadding>
        <ListItem
          button
          key="/billing/feature-usage"
          style={{ paddingLeft: theme.spacing(4) }}
          component={Link}
          selected={selectedAlias === "/billing/feature-usage"}
          to="/billing/feature-usage"
        >
          <ListItemText primary="Feature Usage" />
        </ListItem>
      </List>
    </List>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar} color="inherit">
        <Toolbar>
          <Link to="/" style={{ flexGrow: 1 }}>
            <img src={logo} alt="logo" style={{ width: 100 }} />
          </Link>
          <UserMenu auth={auth} />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <List>{menu}</List>
        </Drawer>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

export default MainLayout
