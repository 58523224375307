import React from "react"
import { GridCellParams, GridColumns, GridOptions, GridRowParams } from "@material-ui/data-grid"
import Table from "../../components/Table"
import { DocumentNode, gql } from "@apollo/client"
import { Grid } from "@material-ui/core"
import EditFeedback from "../../components/Feedback/EditFeedback"
import { FilterFeedbackStatus } from "../../types/feedback"
import { dateColumn } from "../../components/Table/columns/dateColumn"
import { userColumn } from "../../components/Table/columns/userColumn"
import { enumFilterOperators } from "../../components/Table/filterOperators/enumFilterOperators"
import { match, useRouteMatch, useHistory, Route } from "react-router-dom"

const fetchFeedbackList: DocumentNode = gql`
  query Feedback(
    $page: Int
    $perPage: Int
    $sortField: UserFeedbackSortFieldGQLType
    $sortOrder: SortOrderGQLType
    $filterFrom: String
    $filterMessage: String
    $filterAdminComment: String
    $filterStatus: FeedbackFilterStatusGQLType
  ) {
    feedbackList(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filterFrom: $filterFrom
      filterMessage: $filterMessage
      filterAdminComment: $filterAdminComment
      filterStatus: $filterStatus
    ) {
      data {
        id
        chat_id
        from
        sender {
          id
          name
          email
          avatar_url
          role
          created_at
          deleted_at
        }
        message
        payload {
          admin_comment
          cabinet_version
          page_url
          page_screenshot
        }
        created_at
        deleted_at
        acknowledged_at
      }
      meta {
        pagination {
          total_count
          page
          per_page
          last_page
        }
      }
    }
  }
`

const columns: GridColumns = [
  dateColumn({
    field: "created_at",
    headerName: "created_at",
  }),
  userColumn({
    key: "sender",
    field: "from",
    headerName: "from",
    filterable: true,
    type: "search",
  }),
  {
    width: 125,
    field: "page_screenshot",
    filterable: false,
    sortable: false,
    headerName: "screenshot",
    renderCell: (params: GridCellParams): JSX.Element => {
      if (!params.row.payload.page_screenshot) {
        return <></>
      }

      return <img src={params.row.payload.page_screenshot} style={{ width: 50 }} alt="page_screenshot" />
    },
  },
  { flex: 1, field: "message", sortable: false, headerName: "message", type: "search" },
  {
    flex: 1,
    field: "admin_comment",
    sortable: false,
    headerName: "admin_comment",
    type: "search",
    renderCell: (params: GridCellParams): JSX.Element => <>{params.row.payload.admin_comment}</>,
  },
  {
    width: 125,
    field: "acknowledged_at",
    sortable: false,
    headerName: "status",
    filterOperators: enumFilterOperators({
      [FilterFeedbackStatus.Acknowledged]: FilterFeedbackStatus.Acknowledged,
      [FilterFeedbackStatus.Pending]: FilterFeedbackStatus.Pending,
    }),
    renderCell: (params: GridCellParams): JSX.Element => <>{params.value === null ? "pending" : "acknowledged"}</>,
  },
]

function FeedbackPage(): JSX.Element {
  const match: match = useRouteMatch()
  const history: ReturnType<typeof useHistory> = useHistory()

  const onRowClick: GridOptions["onRowClick"] = (params: GridRowParams): void => {
    history.push(`${match.url}/${params.row.id}`)
  }

  return (
    <Grid container spacing={1}>
      <Grid item sm={9}>
        <Table
          columns={columns}
          query={fetchFeedbackList}
          onRowClick={onRowClick}
          getRowClassName={(params: GridRowParams): string => (params.row.deleted_at !== null ? `row-deleted` : "")}
          sortModel={[
            {
              field: "created_at",
              sort: "desc",
            },
          ]}
          fieldsForVariables={{
            from: "filterFrom",
            message: "filterMessage",
            admin_comment: "filterAdminComment",
            acknowledged_at: "filterStatus",
          }}
        />
      </Grid>
      <Grid item sm={3}>
        <Route path={`${match.url}/:id`} component={EditFeedback} />
      </Grid>
    </Grid>
  )
}

export default FeedbackPage
