import React from "react"
import InboxIcon from "@material-ui/icons/Inbox"
import { Box } from "@material-ui/core"

type Props = {
  resourceName?: string
  message?: string
}

function Empty(props: React.PropsWithChildren<Props>): JSX.Element {
  const { message, resourceName } = props

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        fontSize: 18,
        opacity: ".5",
      }}
    >
      <InboxIcon fontSize="large" />
      {!!message ? message : `Please select ${resourceName}`}
    </Box>
  )
}

export default Empty
