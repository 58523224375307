import { Feature } from "./../services/billing/feature"

export type FeatureSettings = {
  value: number // for feature of type flag use 0 = false, 1 = true
}

export enum PlanSchedule {
  Monthly = 1,
  Yearly = 2,
  Permanent = 3,
}

export type PlanFeatures = {
  [key in Feature]?: FeatureSettings
}

export type Plan = {
  id: string
  name: string
  alias: string
  description: string
  price: number
  schedule: PlanSchedule
  features: PlanFeatures
  is_public: boolean
}
