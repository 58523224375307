import React from "react"
import env from "../lib/env"
import { RequestMethod } from "../services/fetchApi"
import useFetch from "./useFetch"

type UseLogout = () => void

export default function useLogout(): UseLogout {
  const [logout, { isFinally }] = useFetch("auth/logout", { method: RequestMethod.Delete })

  React.useEffect(() => {
    if (isFinally) {
      return
    }

    window.location.href = `${env.CABINET_URL}login`
  }, [isFinally])

  return logout
}
