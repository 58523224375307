import { Route, Switch, useRouteMatch, match } from "react-router-dom"
import FeatureUsagePage from "./featureUsage"
import PlansPage from "./plans"
import OrdersPage from "./OrdersPage"

function BillingRouter(): JSX.Element {
  const match: match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.url}/plans`} component={PlansPage} />
      <Route exact path={`${match.url}/feature-usage`} component={FeatureUsagePage} />
      <Route path={`${match.url}/orders`} component={OrdersPage} />
    </Switch>
  )
}

export default BillingRouter
