import env from "../../lib/env"

export enum RequestMethod {
  Post = "POST",
  Get = "GET",
  Delete = "DELETE",
}

export default async function fetchApi(path: string, init?: RequestInit): Promise<Response> {
  const response: Response = await fetch(`${env.API_URL}${path}`, { ...init, credentials: "include" })
  if (response.status === 401 || !response.ok) {
    throw new Error("session not found")
  }

  return response
}
