import { DocumentNode, gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client"
import { Feedback } from "../types/feedback"

const queryFetchFeedback: DocumentNode = gql`
  query Feedback($id: UUID!) {
    feedback(id: $id) {
      id
      chat_id
      from
      sender {
        id
        name
        email
        avatar_url
        role
        created_at
        deleted_at
      }
      message
      payload {
        admin_comment
        cabinet_version
        page_url
        page_screenshot
      }
      created_at
      deleted_at
      acknowledged_at
    }
  }
`
export default function useFetchFeedback(options: QueryHookOptions): QueryResult<{ feedback: Feedback | null }> {
  return useQuery(queryFetchFeedback, options)
}
