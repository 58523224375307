import React from "react"
import { GridCellParams, GridColumns, GridOptions, GridRowParams } from "@material-ui/data-grid"
import Table, { FieldsForVariables } from "../../../components/Table"
import { Card, CardContent, CardHeader, Grid, IconButton, Link } from "@material-ui/core"
import {
  CrawlReport,
  CrawlReportPayloadSettings,
  CrawlReportPayloadStatus,
  CrawlReportStatus,
} from "../../../types/crawlReport"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import env from "../../../lib/env"
import { userColumn } from "../../../components/Table/columns/userColumn"
import { dateColumn } from "../../../components/Table/columns/dateColumn"
import { enumFilterOperators } from "../../../components/Table/filterOperators/enumFilterOperators"
import Copyable from "../../../components/Copyable"
import DateDisplay from "../../../components/DateDisplay"
import Section, { SectionItem } from "../../../components/Section"
import UserAvatar from "../../../components/UserAvatar"
import removeGraphQLFields from "../../../services/utils/removeGraphQLFields"
import { LOAD_CRAWL_REPORTS } from "../../../hooks/useLoadCrawlReports"
import useLoadCrawlReport from "../../../hooks/useLoadCrawlReport"
import { match, Route, useHistory, useParams, useRouteMatch, Link as RouterLink } from "react-router-dom"
import Empty from "../../../components/Empty"

const columns: GridColumns = [
  dateColumn({
    field: "created_at",
    headerName: "created_at",
  }),
  userColumn({
    key: "user",
    field: "created_by",
    headerName: "created_by",
    sortable: false,
    filterable: true,
    type: "search",
  }),
  {
    flex: 1,
    field: "entry_point",
    headerName: "entry_point",
    type: "search",
    renderCell: (params: GridCellParams): JSX.Element => (
      <Link target="_blank" rel="noopener noreferrer" href={params.value as string}>
        {params.value}
      </Link>
    ),
  },
  {
    width: 110,
    field: "budget",
    filterable: false,
    headerName: "budget",
  },
  {
    width: 100,
    field: "status",
    headerName: "status",
    filterOperators: enumFilterOperators({
      [CrawlReportStatus[CrawlReportStatus.New]]: CrawlReportStatus[CrawlReportStatus.New].toLowerCase(),
      [CrawlReportStatus[CrawlReportStatus.Pending]]: CrawlReportStatus[CrawlReportStatus.Pending].toLowerCase(),
      [CrawlReportStatus[CrawlReportStatus.Processing]]: CrawlReportStatus[CrawlReportStatus.Processing].toLowerCase(),
      [CrawlReportStatus[CrawlReportStatus.Processed]]: CrawlReportStatus[CrawlReportStatus.Processed].toLowerCase(),
      [CrawlReportStatus[CrawlReportStatus.Error]]: CrawlReportStatus[CrawlReportStatus.Error].toLowerCase(),
      [CrawlReportStatus[CrawlReportStatus.Deleted]]: CrawlReportStatus[CrawlReportStatus.Deleted].toLowerCase(),
    }),
    renderCell: (params: GridCellParams): JSX.Element => <>{CrawlReportStatus[params.value as number]}</>,
  },
  {
    width: 100,
    field: "actions",
    filterable: false,
    headerName: " ",
    sortable: false,
    renderCell: (params: GridCellParams): JSX.Element => (
      <IconButton
        component={Link}
        target="_blank"
        href={`${env.CABINET_URL}site/${params.row.project.alias}/${params.row.alias}`}
      >
        <OpenInNewIcon />
      </IconButton>
    ),
  },
]

const fieldsForVariables: FieldsForVariables = {
  entry_point: "filterEntryPoint",
  created_by: "filterCreateBy",
  status: "filterStatus",
}

const sortModel: GridOptions["sortModel"] = [
  {
    field: "created_at",
    sort: "desc",
  },
]

const ReportsPage: React.FC = () => {
  const match: match = useRouteMatch()
  const history: ReturnType<typeof useHistory> = useHistory()

  const onRowClick: GridOptions["onRowClick"] = (params: GridRowParams): void => {
    history.push(`${match.url}/${params.row.id}`)
  }

  return (
    <Grid container spacing={1}>
      <Grid item sm={9}>
        <Table
          columns={columns}
          query={LOAD_CRAWL_REPORTS}
          onRowClick={onRowClick}
          sortModel={sortModel}
          fieldsForVariables={fieldsForVariables}
          getRowClassName={(params: GridRowParams): string =>
            params.row.status === CrawlReportStatus.Deleted ? "row-deleted" : ""
          }
        />
      </Grid>
      <Grid item sm={3}>
        <Route path={`${match.url}/:id`} component={EditCrawlReport} />
      </Grid>
    </Grid>
  )
}

const EditCrawlReport: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { data, loading } = useLoadCrawlReport({
    fetchPolicy: "no-cache",
    variables: {
      id,
    },
  })

  if (!id) {
    return <Empty resourceName="report" />
  }

  if (!data || !data.crawlReport || loading) {
    return null
  }

  return (
    <Card>
      <CardHeader
        avatar={<UserAvatar small={false} url={data.crawlReport.user.avatar_url} />}
        title={
          <RouterLink to={`/users/${data.crawlReport.user.id}`}>
            <Link>{data.crawlReport.user.name}</Link>
          </RouterLink>
        }
        subheader={<DateDisplay value={data.crawlReport.created_at} />}
      />
      <CardContent>
        <Section title="Report detail" items={[{ label: "id", value: <Copyable value={data.crawlReport.id} /> }]} />
        <Section
          title="Settings"
          items={removeGraphQLFields(Object.keys(data.crawlReport.payload.settings)).map<SectionItem>(
            (key: string): SectionItem => ({
              label: key,
              value: (data.crawlReport as NonNullable<CrawlReport>).payload.settings[
                key as keyof CrawlReportPayloadSettings
              ].toString(),
            }),
          )}
        />
        {data.crawlReport.payload.status && (
          <Section
            title="Status"
            items={removeGraphQLFields(Object.keys(data.crawlReport.payload.status)).map<SectionItem>(
              (key: string): SectionItem => {
                const value: string | null | boolean | number = ((data.crawlReport as NonNullable<CrawlReport>).payload
                  .status as NonNullable<CrawlReportPayloadStatus>)[key as keyof CrawlReportPayloadStatus]

                return {
                  label: key,
                  value: value === null ? "" : (value as string | boolean | number).toString(),
                }
              },
            )}
          />
        )}
      </CardContent>
    </Card>
  )
}

export default ReportsPage
