import { createMuiTheme, Theme } from "@material-ui/core"
import { blue } from "@material-ui/core/colors"

// TODO: use density mode - https://material-ui.com/customization/density/
const theme: Theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: blue,
  },
})

export default theme
