import React from "react"
import { ThemeProvider } from "@material-ui/core"
import { BrowserRouter } from "react-router-dom"
import { ApolloProvider } from "@apollo/client"
import theme from "./lib/theme"
import useLogin from "./hooks/useLogin"
import AppRouter from "./pages/router"
import apolloClient from "./lib/apolloClient"
import AppInfo from "./components/AppInfo"
import env, { Environment } from "./lib/env"

function App(): JSX.Element | null {
  const { auth } = useLogin()

  if (auth === null) {
    return null
  }

  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <AppRouter auth={auth} />
        </ThemeProvider>
      </ApolloProvider>
      {env.ENVIRONMENT !== Environment.Prod && <AppInfo />}
    </BrowserRouter>
  )
}

export default App
