import { Feature } from "./../services/billing/feature"
import { DocumentNode, gql, useMutation } from "@apollo/client"
import { PlanSchedule } from "../types/plan"

export type UpdatePlanInput = {
  name: string
  alias: string
  description: string
  price: number
  schedule: PlanSchedule
  is_public: boolean
  features: {
    [key in Feature]?: number
  }
}

const query: DocumentNode = gql`
  mutation UpdatePlan($id: UUID!,$input: UpdatePlanGQLInputType!) {
    updatePlan(id:$id, input: $input) {
      id
      name
      alias
      description
      price
      schedule
      features {
        ${Feature.CrawlBudgetTotal} {
          value
        }
        ${Feature.CrawlMaxThreadCount} {
          value
        }
      }
    }
  }
`
export default function useUpdatePlan(): ReturnType<typeof useMutation> {
  return useMutation(query)
}
