import { Grid, Link } from "@material-ui/core"
import { GridCellParams, GridColumns, GridOptions, GridRowParams } from "@material-ui/data-grid"
import React from "react"
import Table, { FieldsForVariables } from "../../../components/Table"
import { dateColumn } from "../../../components/Table/columns/dateColumn"
import { queryCrawlReportSharedLinks } from "../../../hooks/useFetchCrawlReportSharedLinks"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import env from "../../../lib/env"
import { userColumn } from "../../../components/Table/columns/userColumn"

const columns: GridColumns = [
  userColumn({
    key: "user",
    field: "created_by",
    headerName: "created_by",
    sortable: false,
    filterable: true,
    type: "search",
    flex: 1,
  }),
  dateColumn({
    field: "expires_at",
    headerName: "expires_at",
  }),
  dateColumn({
    field: "created_at",
    headerName: "created_at",
  }),
  dateColumn({
    field: "deleted_at",
    headerName: "deleted_at",
  }),
  {
    field: "token",
    headerName: "link",
    renderCell: (params: GridCellParams): JSX.Element => (
      <Link target="_blank" rel="noopener noreferrer" href={`${env.CABINET_URL}s/${params.value}/${params.row.path}`}>
        <OpenInNewIcon />
      </Link>
    ),
  },
]

const fieldsForVariables: FieldsForVariables = {
  created_by: "filterCreateBy",
}

const sortModel: GridOptions["sortModel"] = [
  {
    field: "created_at",
    sort: "desc",
  },
]

const SharedLinkPage: React.FC = () => {
  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <Table
          columns={columns}
          query={queryCrawlReportSharedLinks}
          sortModel={sortModel}
          fieldsForVariables={fieldsForVariables}
          getRowClassName={(params: GridRowParams): string =>
            params.row.deleted_at || new Date(parseInt(params.row.expires_at)).getTime() < new Date().getTime()
              ? "row-deleted"
              : ""
          }
        />
      </Grid>
    </Grid>
  )
}

export default SharedLinkPage
