import { Route, Switch, useRouteMatch, match } from "react-router-dom"
import ReportsPage from "./index"
import SharedLinksPage from "./SharedLinksPage"

function ReportsRouter(): JSX.Element {
  const match: match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${match.url}/shared-links`} component={SharedLinksPage} />
      <Route path={match.url} component={ReportsPage} />
    </Switch>
  )
}

export default ReportsRouter
