import { DocumentNode, gql } from "@apollo/client"
import { User } from "./../types/user"
import useQueryTable, { UseQueryTable } from "./useQueryTable"

export const LOAD_USERS: DocumentNode = gql`
  query Users(
    $page: Int
    $perPage: Int
    $sortField: UsersSortFieldGQLType
    $sortOrder: SortOrderGQLType
    $filterName: String
    $filterEmail: String
    $filterRole: FilterRoleGQLType
  ) {
    users(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filterName: $filterName
      filterEmail: $filterEmail
      filterRole: $filterRole
    ) {
      data {
        id
        name
        email
        avatar_url
        role
        created_at
        deleted_at
        profile {
          payload {
            name
            email
            phone
            company
            position
          }
        }
      }
      meta {
        pagination {
          total_count
          page
          per_page
          last_page
        }
      }
    }
  }
`

export default function useLoadPlans(): UseQueryTable<"users", User> {
  return useQueryTable(LOAD_USERS)
}
