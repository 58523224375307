import { DocumentNode, gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client"
import { CrawlReportSharedLink } from "../types/crawlReportSharedLink"

export const queryCrawlReportSharedLinks: DocumentNode = gql`
  query CrawlReportSharedLinks(
    $page: Int
    $perPage: Int
    $sortField: CrawlReportSharedLinksSortFieldGQLType
    $sortOrder: SortOrderGQLType
    $filterCreateBy: UUID
    $filterId: UUID
  ) {
    crawlReportSharedLinks(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filterCreateBy: $filterCreateBy
      filterId: $filterId
    ) {
      data {
        id
        created_by
        report_id
        path
        token
        created_at
        expires_at
        deleted_at
        user {
          id
          name
          email
          avatar_url
          role
          created_at
          deleted_at
        }
      }
      meta {
        pagination {
          total_count
          page
          per_page
          last_page
        }
      }
    }
  }
`

const useFetchCrawlReportSharedLinks = (
  options: QueryHookOptions,
): QueryResult<{ sharedLinks: CrawlReportSharedLink }> => {
  return useQuery(queryCrawlReportSharedLinks, options)
}

export default useFetchCrawlReportSharedLinks
