import React from "react"
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Card,
  CardContent,
  IconButton,
  Snackbar,
  Checkbox,
  Typography,
  FormControlLabel,
  TextField,
} from "@material-ui/core"
import { GridCellParams, GridColumns, GridOptions, GridRowParams } from "@material-ui/data-grid"
import { Add, Edit } from "@material-ui/icons"
import Table from "../../components/Table"
import { Plan, PlanSchedule } from "../../types/plan"
import { Feature } from "../../services/billing/feature"
import useCreatePlan, { CreatePlanInput } from "../../hooks/useCreatePlan"
import useUpdatePlan, { UpdatePlanInput } from "../../hooks/useUpdatePlan"
import { LOAD_PLANS } from "../../hooks/useLoadPlans"
import Empty from "../../components/Empty"
import removeGraphQLFields from "../../services/utils/removeGraphQLFields"
import Section from "../../components/Section"
import { Alert } from "@material-ui/lab"

function PlansPage(): JSX.Element {
  const [plan, setPlan] = React.useState<Plan | null>(null)
  const [editPlan, setEditPlan] = React.useState<Plan>()
  const [updateTable, setUpdateTable] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)

  React.useEffect(() => {
    if (updateTable) {
      setUpdateTable(false)
    }
  }, [updateTable])

  const columns: GridColumns = [
    { flex: 1, field: "name", sortable: false, headerName: "name", type: "search" },
    { flex: 1, field: "alias", sortable: false, headerName: "alias", type: "search" },
    { flex: 1, field: "description", sortable: false, headerName: "description", type: "search" },
    { flex: 1, field: "price", sortable: false, headerName: "price", type: "search" },
    {
      flex: 1,
      field: "schedule",
      sortable: false,
      headerName: "schedule",
      type: "search",
      renderCell: (params: GridCellParams): JSX.Element => {
        const search: string = PlanSchedule[params.value as number]
        if (!search) {
          return <>Unknown search ({params.value})</>
        }

        return <>{search}</>
      },
    },
    {
      flex: 1,
      field: "is_public",
      sortable: false,
      headerName: "is_public",
      renderCell: (params: GridCellParams): JSX.Element => {
        return <Checkbox disabled checked={params.value as boolean} />
      },
    },
    {
      field: "actions",
      filterable: false,
      headerName: " ",
      sortable: false,
      renderCell: (params: GridCellParams): JSX.Element => (
        <IconButton
          onClick={(e: React.MouseEvent): void => {
            e.stopPropagation()
            setEditPlan(params.row as Plan)
            setShowModal(true)
          }}
        >
          <Edit />
        </IconButton>
      ),
    },
  ]

  const createPlan = (): void => {
    setShowModal(true)
  }

  const selectPlan: GridOptions["onRowClick"] = (params: GridRowParams): void => {
    setPlan(params.row as Plan)
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <Button onClick={createPlan} startIcon={<Add />}>
            Create
          </Button>
        </Grid>
        <Grid item sm={9}>
          <Table columns={columns} query={LOAD_PLANS} forceUpdate={updateTable} onRowClick={selectPlan} />
        </Grid>
        <Grid item sm={3}>
          {!plan ? <Empty resourceName="plan" /> : <EditPlan plan={plan} />}
        </Grid>
      </Grid>
      <CreatePlanModal
        open={showModal}
        plan={editPlan}
        onClose={(): void => {
          setShowModal(false)
          setUpdateTable(true)
          if (editPlan) {
            setEditPlan(undefined)
          }
        }}
      />
    </>
  )
}

export default PlansPage

type EditPlanProps = {
  plan: Plan
}

function EditPlan(props: React.PropsWithChildren<EditPlanProps>): JSX.Element {
  const { plan } = props

  const activeFeatures: string[] = removeGraphQLFields(Object.keys(plan.features)).filter(
    (key: string) => plan.features[key as Feature],
  )

  return (
    <Card>
      <CardContent>
        <Section
          title="Plan detail"
          items={removeGraphQLFields(Object.keys(plan))
            .filter((key: string) => key !== "features")
            .map((key: string) => {
              let value: string | number | boolean = plan[key as keyof Omit<Plan, "features">]
              if (key === "schedule") {
                value = PlanSchedule[plan[key as keyof Plan] as PlanSchedule]
              } else if (key === "is_public") {
                value = value.toString()
              }

              return {
                label: key,
                value,
              }
            })}
        />
        {!!activeFeatures.length && (
          <Section
            title="Features"
            items={activeFeatures.map((key: string) => ({
              label: key,
              value: plan.features[key as Feature]?.value,
            }))}
          />
        )}
      </CardContent>
    </Card>
  )
}

type CreatePlanModalProps = {
  plan?: Plan
  open: boolean
  onClose: () => void
}

function CreatePlanModal(props: React.PropsWithChildren<CreatePlanModalProps>): JSX.Element {
  const { plan, open, onClose } = props

  const [ErrorMessage, setErrorMessage] = React.useState()

  const [createPlan] = useCreatePlan()
  const [updatePlan] = useUpdatePlan()

  const [name, setName] = React.useState("")
  const [alias, setAlias] = React.useState("")
  const [description, setDescription] = React.useState("")
  const [price, setPrice] = React.useState("")
  const [isPublic, setIsPublic] = React.useState(false)
  const [schedule, setSchedule] = React.useState("")
  const [crawlBudgetTotal, setCrawlBudgetTotal] = React.useState("")
  const [crawlMaxThreadCount, setCrawlMaxThreadCount] = React.useState("")

  React.useEffect(() => {
    if (plan) {
      setName(plan.name)
      setAlias(plan.alias)
      setDescription(plan.description)
      setPrice(plan.price.toString())
      setIsPublic(plan.is_public)
      setSchedule(plan.schedule.toString())
      if (plan.features.crawl_budget_total) {
        setCrawlBudgetTotal(plan.features.crawl_budget_total?.value.toString())
      }
      if (plan.features.crawl_max_thread_count) {
        setCrawlMaxThreadCount(plan.features.crawl_max_thread_count?.value.toString())
      }
    }
  }, [plan])

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    if (!name || !alias || !description || !price || !schedule) {
      return
    }

    const input: UpdatePlanInput | CreatePlanInput = {
      name,
      alias,
      description,
      price: parseInt(price),
      schedule: parseInt(schedule) as PlanSchedule,
      is_public: isPublic,
      features: {
        [Feature.CrawlBudgetTotal]: parseInt(crawlBudgetTotal),
        [Feature.CrawlMaxThreadCount]: parseInt(crawlMaxThreadCount),
      },
    }

    try {
      if (plan) {
        await updatePlan({
          variables: {
            id: plan.id,
            input,
          },
        })
      } else {
        await createPlan({
          variables: {
            input,
          },
        })
      }
      onClose()
    } catch (e) {
      setErrorMessage(e.message)
    }
  }

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.currentTarget.value)
  }
  const onChangeAlias = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAlias(e.currentTarget.value)
  }
  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setDescription(e.currentTarget.value)
  }
  const onChangePrice = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPrice(e.currentTarget.value)
  }
  const onChangeSchedule = (e: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
    setSchedule(e.target.value as string)
  }
  const onChangeIsPublic = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsPublic(e.target.checked)
  }
  const onChangeCrawlBudgetTotal = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCrawlBudgetTotal(e.currentTarget.value)
  }
  const onChangeCrawlMaxThreadCount = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCrawlMaxThreadCount(e.currentTarget.value)
  }

  return (
    <Dialog open={open} onClose={(): void => onClose()}>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Typography variant="h5">{plan ? "Update plan" : "Create plan"}</Typography>
          <TextField
            name="name"
            value={name}
            onChange={onChangeName}
            label="Name"
            type="text"
            required={true}
            margin="dense"
            fullWidth={true}
          />
          <TextField
            name="alias"
            value={alias}
            label="Alias"
            type="text"
            required={true}
            margin="dense"
            fullWidth={true}
            onChange={onChangeAlias}
          />
          <TextField
            name="description"
            value={description}
            label="Description"
            type="text"
            multiline={true}
            rows={5}
            required={true}
            margin="dense"
            fullWidth={true}
            onChange={onChangeDescription}
          />
          <TextField
            name="price"
            value={price}
            label="Price"
            type="number"
            required={true}
            margin="dense"
            fullWidth={true}
            onChange={onChangePrice}
          />
          <TextField
            name="schedule"
            label="Schedule"
            type="number"
            required={true}
            select={true}
            margin="dense"
            fullWidth={true}
            value={schedule}
            SelectProps={{ onChange: onChangeSchedule }}
          >
            {[PlanSchedule.Monthly, PlanSchedule.Yearly, PlanSchedule.Permanent].map((value: number) => (
              <MenuItem key={value} value={value}>
                {PlanSchedule[value]}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel
            label="Public"
            control={<Checkbox checked={isPublic} onChange={onChangeIsPublic} name="is_public" />}
          />
          <Typography
            variant="h5"
            style={{
              marginTop: 15,
            }}
          >
            Features
          </Typography>
          <TextField
            name={Feature.CrawlBudgetTotal}
            label="CrawlBudgetTotal"
            type="number"
            margin="dense"
            value={crawlBudgetTotal}
            fullWidth={true}
            onChange={onChangeCrawlBudgetTotal}
          />
          <TextField
            name={Feature.CrawlMaxThreadCount}
            label="CrawlMaxThreadCount"
            type="number"
            margin="dense"
            value={crawlMaxThreadCount}
            fullWidth={true}
            onChange={onChangeCrawlMaxThreadCount}
          />
          <DialogActions>
            <Button key="form-create-plan__button-cancel" color="primary" onClick={(): void => onClose()}>
              Cancel
            </Button>
            <Button key="form-create-plan__submit-cancel" type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!ErrorMessage}
        autoHideDuration={3000}
        onClose={(): void => {
          setErrorMessage(undefined)
        }}
      >
        <Alert severity="error">{ErrorMessage}</Alert>
      </Snackbar>
    </Dialog>
  )
}
