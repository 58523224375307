import { DocumentNode, gql, QueryHookOptions } from "@apollo/client"
import { CrawlReport } from "../types/crawlReport"
import useQueryTable, { UseQueryTable } from "./useQueryTable"

export const LOAD_CRAWL_REPORTS: DocumentNode = gql`
  query CrawlReports(
    $page: Int
    $perPage: Int
    $sortField: CrawlReportSortFieldGQLType
    $sortOrder: SortOrderGQLType
    $filterEntryPoint: String
    $filterCreateBy: String
    $filterStatus: CrawlReportFilterStatusGQLType
    $filterId: UUID
  ) {
    crawlReports(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filterEntryPoint: $filterEntryPoint
      filterCreateBy: $filterCreateBy
      filterStatus: $filterStatus
      filterId: $filterId
    ) {
      data {
        id
        created_by
        project_id
        entry_point
        budget
        budget_used
        thread_count
        status
        alias
        project {
          alias
        }
        payload {
          settings {
            thread_count
            thread_sleep_ms
            budget
            max_dfi
            timeout_ms
            sitemaps
            use_robotstxt
            use_metarobots
            use_canonical
            follow_subdomains
            treat_www_as_same_host
            follow_nofollow_links
            fetch_external_links
            user_agent
            robots_user_agent
          }
          status {
            budget_used
            resources_enqueued
            speed
            crawl_done
            done
            started_at
            crawl_finished_at
            finished_at
            error
          }
          result {
            robotstxt_lines
          }
        }
        user {
          id
          name
          email
          avatar_url
          role
          created_at
          deleted_at
        }
        created_at
        updated_at
        deleted_at
      }
      meta {
        pagination {
          total_count
          page
          per_page
          last_page
        }
      }
    }
  }
`

export default function useLoadCrawlReports(options: QueryHookOptions): UseQueryTable<"crawlReports", CrawlReport> {
  return useQueryTable(LOAD_CRAWL_REPORTS, options)
}
