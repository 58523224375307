export function openBase64InNewWindow(base64: string): void {
  const newWindow: Window | null = window.open()
  if (!newWindow) {
    return
  }

  newWindow.document.open()
  newWindow.document.write(`<iframe width="100%" height="100%" src="${base64}"></iframe>`)
  newWindow.document.close()
}
