import { gql } from "@apollo/client"
import { DocumentNode } from "graphql"
import { Feature } from "../services/billing/feature"
import { Plan } from "../types/plan"
import useQueryTable, { UseQueryTable } from "./useQueryTable"

export const LOAD_PLANS: DocumentNode = gql`
  query Plans($page: Int, $perPage: Int, $sortField: PlanSortFieldGQLType, $sortOrder: SortOrderGQLType) {
    plans(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder) {
      data {
        id
        name
        alias
        description
        price
        schedule
        features {
          ${Feature.CrawlBudgetTotal} {
            value
          }
          ${Feature.CrawlMaxThreadCount} {
            value
          }
        }
        is_public
      }
      meta {
        pagination {
          total_count
          page
          per_page
          last_page
        }
      }
    }
  }
`
export default function useLoadPlans(): UseQueryTable<"plans", Plan> {
  return useQueryTable(LOAD_PLANS)
}
