import { DocumentNode, gql, useMutation } from "@apollo/client"
import React, { ChangeEvent } from "react"
import { SwitchBaseProps } from "@material-ui/core/internal/SwitchBase"
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Chip,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@material-ui/core"
import { openBase64InNewWindow } from "../../services/utils/openBase64InNewWindow"
import DeleteIcon from "@material-ui/icons/Delete"
import UserAvatar from "../UserAvatar"
import DateDisplay from "../DateDisplay"
import useFetchFeedback from "../../hooks/useFetchFeedback"
import { Link as RouterLink, useParams } from "react-router-dom"
import { Feedback } from "../../types/feedback"

const UPDATE_FEEDBACK: DocumentNode = gql`
  mutation UpdateFeedback($id: UUID!, $input: UpdateFeedbackGQLInputType!) {
    updateFeedback(id: $id, input: $input) {
      id
      chat_id
      from
      sender {
        id
        name
        email
        avatar_url
        role
        created_at
        deleted_at
      }
      message
      payload {
        cabinet_version
        admin_comment
        page_url
        page_screenshot
      }
      created_at
      deleted_at
      acknowledged_at
    }
  }
`

const DELETE_FEEDBACK: DocumentNode = gql`
  mutation DeleteFeedback($id: UUID!) {
    deleteFeedback(id: $id) {
      id
      chat_id
      from
      sender {
        id
        name
        email
        avatar_url
        role
        created_at
        deleted_at
      }
      message
      payload {
        cabinet_version
        admin_comment
        page_url
        page_screenshot
      }
      created_at
      deleted_at
      acknowledged_at
    }
  }
`

const EditFeedback: React.FC = () => {
  const { id }: { id: string } = useParams()

  const { data, loading } = useFetchFeedback({
    fetchPolicy: "no-cache",
    variables: {
      id,
    },
  })

  const [fetchUpdateFeedback] = useMutation(UPDATE_FEEDBACK)
  const [fetchDeleteFeedback] = useMutation(DELETE_FEEDBACK)

  const [adminComment, setAdminComment] = React.useState("")
  const [acknowledged, setAcknowledged] = React.useState(false)

  React.useEffect(() => {
    const feedback: Feedback | null | undefined = data?.feedback
    if (feedback) {
      setAdminComment(feedback.payload.admin_comment ?? "")
      setAcknowledged(feedback.acknowledged_at !== null)
    }
  }, [data])

  const feedback: Feedback | null | undefined = data?.feedback
  if (!feedback || loading) {
    return null
  }

  const updateFeedback = async (): Promise<void> => {
    let acknowledgedAt: string | null = null
    if (acknowledged) {
      acknowledgedAt = feedback.acknowledged_at !== null ? feedback.acknowledged_at : new Date().toISOString()
    }

    await fetchUpdateFeedback({
      variables: {
        id,
        input: {
          adminComment,
          acknowledgedAt: acknowledgedAt,
        },
      },
    })
  }

  const deleteFeedback = async (): Promise<void> => {
    await fetchDeleteFeedback({
      variables: {
        id,
      },
    })
  }

  const onChangeAcknowledged: SwitchBaseProps["onChange"] = (): void => {
    setAcknowledged((value: boolean) => !value)
  }

  const onChangeAdminComment: StandardInputProps["onChange"] = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setAdminComment(event.target.value)
  }

  return (
    <Card>
      <CardHeader
        avatar={<UserAvatar small={false} url={feedback.sender.avatar_url} />}
        title={
          <RouterLink to={`/users/${feedback.sender.id}`}>
            <Link>{feedback.sender.name}</Link>
          </RouterLink>
        }
        subheader={<DateDisplay value={feedback.created_at} />}
      />
      {feedback.payload.page_screenshot !== null && (
        <CardMedia
          style={{
            height: 0,
            paddingTop: "56.25%",
            cursor: "pointer",
          }}
          onClick={(): void => openBase64InNewWindow(feedback.payload.page_screenshot as string)}
          image={feedback.payload.page_screenshot}
          title="Paella dish"
        />
      )}
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Feedback detail
        </Typography>
        <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
          Page url
        </Typography>
        <Typography variant="body2" gutterBottom>
          <Link href={feedback.payload.page_url} target="_blank" rel="noreferrer noopener">
            {feedback.payload.page_url}
          </Link>
        </Typography>
        <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
          Cabinet version
        </Typography>
        <Typography variant="body2" gutterBottom>
          <Chip size="small" label={feedback.payload.cabinet_version} />
        </Typography>
        <Card variant="outlined">
          <CardContent>
            <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
              Message
            </Typography>
            <Typography style={{ whiteSpace: "pre-line" }} variant="body2" gutterBottom>
              {feedback.message}
            </Typography>
          </CardContent>
        </Card>
        <form style={{ clear: "both" }}>
          <TextField
            margin="dense"
            label="Admin comment (for internal use)"
            placeholder="Comment"
            value={adminComment}
            onChange={onChangeAdminComment}
            multiline
            fullWidth
          />
          <FormControlLabel
            control={<Checkbox checked={acknowledged} onChange={onChangeAcknowledged} />}
            label="Acknowledged"
          />
        </form>
      </CardContent>
      <CardActions>
        <Button onClick={updateFeedback} variant="contained" color="primary">
          Update
        </Button>
        {feedback.deleted_at === null && (
          <Button onClick={deleteFeedback} color="secondary">
            <DeleteIcon />
            Delete
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

export default EditFeedback
