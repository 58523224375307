import React from "react"
import { Chip, IconButton, Tooltip } from "@material-ui/core"
import { FileCopy } from "@material-ui/icons"
import DoneIcon from "@material-ui/icons/Done"

type Props = {
  value: string
}

const DEFAULT_TITLE: string = "Copy"
const TITLE_AFTER_COPY: string = "Copied"
const TIMEOUT_AFTER_COPY: number = 2500

export default function Copyable(props: Props): JSX.Element {
  const { value } = props

  const [title, setTitle] = React.useState(DEFAULT_TITLE)

  const copy = (): void => {
    try {
      navigator.clipboard.writeText(value)
      setTitle(TITLE_AFTER_COPY)
      setTimeout(() => setTitle(DEFAULT_TITLE), TIMEOUT_AFTER_COPY)
    } catch (e) {}
  }

  return (
    <div style={{ display: "flex" }}>
      <Chip size="small" label={value} style={{ borderRadius: "0" }} />
      <Tooltip placement="top" title={title}>
        <IconButton size="small" onClick={copy}>
          {title === DEFAULT_TITLE ? <FileCopy style={{ fontSize: 14 }} /> : <DoneIcon style={{ fontSize: 14 }} />}
        </IconButton>
      </Tooltip>
    </div>
  )
}
