import { AuthResponse } from "../../hooks/useLogin"
import { Avatar, IconButton, Menu, MenuItem } from "@material-ui/core"
import PersonIcon from "@material-ui/icons/Person"
import React from "react"
import useLogout from "../../hooks/useLogout"
import env from "../../lib/env"

type Props = {
  auth: AuthResponse
}

function UserMenu(props: Props): JSX.Element {
  const { auth } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const logout: () => void = useLogout()

  const avatarUrl: string = auth.user.avatar_url

  const showUserMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const closeUserMenu = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={showUserMenu} color="inherit">
        {avatarUrl === null ? (
          <Avatar>
            <PersonIcon />
          </Avatar>
        ) : (
          <Avatar src={avatarUrl as string} />
        )}
      </IconButton>
      <Menu anchorEl={anchorEl} onClose={closeUserMenu} open={anchorEl !== null}>
        <MenuItem component="a" href={env.CABINET_URL} target="_blank" rel="noopener noreferrer">
          Cabinet
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
