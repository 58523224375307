import React from "react"

type Props = {
  value: string | null
}

function DateDisplay(props: React.PropsWithChildren<Props>): JSX.Element {
  const { value } = props
  if (value === null) {
    return <></>
  }

  return <>{new Date(parseInt(value as string)).toLocaleString()}</>
}

export default DateDisplay
