import { Avatar } from "@material-ui/core"
import theme from "../../lib/theme"
import PersonIcon from "@material-ui/icons/Person"
import React from "react"

type Props = {
  url: string | null
  name?: JSX.Element
  small?: boolean
}

function UserAvatar(props: React.PropsWithChildren<Props>): JSX.Element {
  const { url, name, small = true } = props
  let style: React.CSSProperties | undefined
  if (small) {
    style = { height: theme.spacing(3), width: theme.spacing(3) }
  }

  const avatarComponent: JSX.Element =
    url === null ? (
      <Avatar style={style}>
        <PersonIcon />
      </Avatar>
    ) : (
      <Avatar style={style} src={url as string} />
    )

  return (
    <>
      {avatarComponent}
      {name !== undefined && <span style={{ marginLeft: 15 }}>{name}</span>}
    </>
  )
}

export default UserAvatar
