import { GridCellParams, GridColDef } from "@material-ui/data-grid"
import React from "react"
import DateDisplay from "../../DateDisplay"

export function dateColumn(options: GridColDef): GridColDef {
  return {
    width: 200,
    filterable: false,
    ...options,
    renderCell: (params: GridCellParams): JSX.Element => <DateDisplay value={params.value as string | null} />,
  }
}
