import React from "react"
import fetchApi from "../services/fetchApi"

export type UseFetch<R> = [
  () => Promise<void>,
  { data: R | null; isLoading: boolean; error: Error | null; isFinally: boolean },
]

export default function useFetch<R>(path: string, init?: RequestInit): UseFetch<R> {
  const [data, setData] = React.useState<R | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isFinally, setIsFinally] = React.useState(true)
  const [error, setError] = React.useState(null)

  const callback = async (): Promise<void> => {
    try {
      setIsLoading(true)
      setIsFinally(false)
      setData(null)
      const response: Response = await fetchApi(path, init)
      const responseData: R = await response.json()
      setData(responseData)
    } catch (error) {
      setError(error)
    } finally {
      setIsLoading(false)
      setIsFinally(true)
    }
  }

  return [callback, { data, isLoading, error, isFinally }]
}
