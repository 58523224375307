import React from "react"
import {
  DataGrid,
  DataGridProps,
  GridFilterItem,
  GridFilterModelParams,
  GridOptions,
  GridPageChangeParams,
  GridSortModel,
  GridSortModelParams,
  GridToolbar,
} from "@material-ui/data-grid"
import { DocumentNode } from "@apollo/client"
import useQueryTable, { ResponseTable, VariablesTableSortable } from "../../hooks/useQueryTable"
import { Alert } from "@material-ui/lab"
import { getPageSize, setPageSize } from "../../services/localStorage/paginationPageSize"
import columnTypes from "./columnTypes"
import { createStyles, makeStyles, StyleRules, Theme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/styles/withStyles"

type classKey = "root"

type useStylesClassMap = ClassNameMap<classKey>

const useStyles: () => useStylesClassMap = makeStyles(
  (theme: Theme): StyleRules<classKey> =>
    createStyles({
      root: {
        border: 0,
        color: theme.palette.type === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.85)",
        "& .MuiDataGrid-columnsContainer": {
          backgroundColor: theme.palette.background.paper,
        },
        "& .MuiDataGrid-dataContainer": {
          backgroundColor: theme.palette.background.paper,
        },
        "& .MuiDataGrid-row": {
          borderRight: theme.palette.background.default,
        },
        "& .MuiDataGrid-cell": {
          color: theme.palette.type === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.65)",
        },
        "& .MuiDataGrid-footer": {
          backgroundColor: theme.palette.background.paper,
        },
      },
    }),
)

export type FieldsForVariables = {
  [field: string]: string
}

type Props = Omit<DataGridProps, "rows"> & {
  query: DocumentNode
  fieldsForVariables?: FieldsForVariables
  forceUpdate?: boolean
}

const Table = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const classes: useStylesClassMap = useStyles()
  const { query, fieldsForVariables, forceUpdate = false } = props
  const { loading, data = {}, error, fetchMore, refetch } = useQueryTable(query)

  const methodName: string = Object.keys(data).length ? Object.keys(data)[0] : ""
  const pagination: ResponseTable[string]["meta"]["pagination"] | undefined = data[methodName]?.meta.pagination

  React.useEffect(() => {
    if (forceUpdate) {
      const update = async (): Promise<void> => {
        await refetch()
      }
      update()
    }
  }, [forceUpdate])

  const pageChange: GridOptions["onPageChange"] = async (params: GridPageChangeParams) => {
    await fetchMore({
      variables: {
        perPage: params.pageSize,
        page: params.page + 1,
      },
    })
  }

  const pageSizeChange: GridOptions["onPageChange"] = async (params: GridPageChangeParams) => {
    setPageSize(params.pageSize)
    await fetchMore({
      variables: {
        perPage: params.pageSize,
        page: 1,
      },
    })
  }

  const getActiveSort = (sortModel: GridSortModel): VariablesTableSortable => {
    if (!sortModel.length) {
      return {}
    }

    return {
      sortField: sortModel[0].field,
      sortOrder: sortModel[0].sort,
    }
  }

  const getActiveFilters = (filterItems: GridFilterItem[]): FieldsForVariables => {
    if (!fieldsForVariables) {
      return {}
    }

    const filterItemsWithValue: GridFilterItem[] = filterItems.filter((item: GridFilterItem): boolean =>
      Boolean(item.value),
    )
    if (!filterItemsWithValue.length) {
      return {}
    }

    const variables: { [key: string]: string } = {}
    for (const [field, variable] of Object.entries(fieldsForVariables)) {
      const activeFilter: GridFilterItem | undefined = filterItemsWithValue.find(
        (item: GridFilterItem): boolean => item.columnField === field,
      )
      if (!activeFilter) {
        continue
      }

      variables[variable] = activeFilter.value as string
    }

    return variables
  }

  const sortChange: GridOptions["onSortModelChange"] = async (params: GridSortModelParams) => {
    const { sortModel, api } = params
    await fetchMore({
      variables: {
        page: 1,
        perPage: getPageSize(),
        ...getActiveSort(sortModel),
        ...getActiveFilters(api.getState().filter.items),
      },
    })
  }

  const filterChange: GridOptions["onFilterModelChange"] = async (params: GridFilterModelParams): Promise<void> => {
    const { filterModel, api } = params
    await fetchMore({
      variables: {
        page: 1,
        perPage: getPageSize(),
        ...getActiveFilters(filterModel.items),
        ...getActiveSort(api.getSortModel()),
      },
    })
  }

  if (error) {
    return <Alert severity="error">{error?.message}</Alert>
  }

  return (
    <DataGrid
      className={classes.root}
      disableExtendRowFullWidth={false}
      loading={loading}
      page={!pagination || pagination.page <= 1 ? 0 : pagination.page - 1}
      pageSize={getPageSize()}
      autoHeight={true}
      rows={data[methodName] ? data[methodName].data : []}
      rowCount={pagination?.total_count}
      paginationMode="server"
      filterMode="server"
      sortingMode="server"
      onPageChange={pageChange}
      onPageSizeChange={pageSizeChange}
      onSortModelChange={sortChange}
      onFilterModelChange={filterChange}
      rowsPerPageOptions={[10, 25, 100, 500]}
      columnTypes={columnTypes}
      density="compact"
      components={{
        Toolbar: GridToolbar,
      }}
      {...props}
    />
  )
}

export default Table
