import { gql, useMutation } from "@apollo/client"
import { DocumentNode } from "graphql"
import { Feature } from "../services/billing/feature"
import { PlanSchedule } from "../types/plan"

export type CreatePlanInput = {
  name: string
  alias: string
  description: string
  price: number
  schedule: PlanSchedule
  is_public: boolean
  features: {
    [key in Feature]?: number
  }
}

const query: DocumentNode = gql`
  mutation CreatePlan($input: CreatePlanGQLInputType!) {
    createPlan(input: $input) {
      id
      name
      alias
      description
      price
      schedule
      features {
        ${Feature.CrawlBudgetTotal} {
          value
        }
        ${Feature.CrawlMaxThreadCount} {
          value
        }
      }
    }
  }
`
export default function useCreatePlan(): ReturnType<typeof useMutation> {
  return useMutation(query)
}
