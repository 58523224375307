import { User } from "./user"

export enum FilterFeedbackStatus {
  Acknowledged = "acknowledged",
  Pending = "pending",
}

export type Feedback = {
  id: string
  chat_id: string
  from: string
  message: string
  sender: User
  payload: {
    cabinet_version: string
    page_url: string
    page_screenshot: string | null
    admin_comment: string | null
  }
  created_at: string
  deleted_at: string | null
  acknowledged_at: string | null
}
