import { UserRole } from "../types/user"
import React from "react"
import env from "../lib/env"
import useFetch from "./useFetch"

export type AuthResponse = {
  success: boolean
  session: {
    token: string
  }
  user: {
    id: string
    avatar_url: string
    role: UserRole
  }
  user_profile: {
    name: string
    email: string
    phone?: string
    company?: string
    position?: string
  }
  need_register?: boolean
}

export type UseLoginResult = {
  auth: AuthResponse | null
}

export default function useLogin(): UseLoginResult {
  const [auth, setAuth] = React.useState<AuthResponse | null>(null)

  const [login, { data, error, isLoading }] = useFetch<AuthResponse>("auth/session")

  React.useEffect(() => {
    login()
  }, [])

  React.useEffect(() => {
    if (isLoading) {
      return
    }

    if (error || (data && data.user.role !== UserRole.Admin)) {
      window.location.href = `${env.CABINET_URL}login`
      return
    }

    setAuth(data)
  }, [isLoading])

  return { auth }
}
