import { DocumentNode, QueryHookOptions, QueryResult, useQuery } from "@apollo/client"
import { GridSortDirection } from "@material-ui/data-grid"
import { getPageSize } from "../services/localStorage/paginationPageSize"

export type VariablesTableSortable = {
  sortField?: string
  sortOrder?: GridSortDirection
}

export type VariablesTable = VariablesTableSortable & {
  page?: number
  perPage: number
}

export type ResponseTable<Name extends string = string, Rec extends {} = {}> = {
  [key in Name]: {
    data: Rec[]
    meta: {
      pagination: {
        total_count: number
        page: number
        per_page: number
        last_page: number
      }
    }
  }
}

export type UseQueryTable<Name extends string = string, Rec extends {} = {}> = QueryResult<
  ResponseTable<Name, Rec>,
  VariablesTable
>

export default function useQueryTable<Name extends string = string, Rec extends {} = {}>(
  query: DocumentNode,
  options?: QueryHookOptions,
): UseQueryTable<Name, Rec> {
  return useQuery<ResponseTable<Name, Rec>, VariablesTable>(query, {
    ...options,
    variables: {
      page: 1,
      perPage: getPageSize(),
      ...options?.variables,
    },
  })
}
