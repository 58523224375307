import React from "react"
import { Card, CardContent, CardHeader, Chip, Grid } from "@material-ui/core"
import { GridCellParams, GridColumns, GridOptions, GridRowParams } from "@material-ui/data-grid"
import Table from "./../../components/Table"
import { LOAD_ORDERS } from "../../hooks/useLoadOrders"
import { dateColumn } from "../../components/Table/columns/dateColumn"
import { match, Route, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { Order, OrderDetails, OrderPayload, OrderStatus, PaymentSystem } from "../../types/order"
import DoneIcon from "@material-ui/icons/Done"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import DateDisplay from "../../components/DateDisplay"
import UserAvatar from "../../components/UserAvatar"
import useLoadOrder from "../../hooks/useLoadOrder"
import { userColumn } from "../../components/Table/columns/userColumn"
import Section, { SectionItem } from "../../components/Section"
import removeGraphQLFields from "../../services/utils/removeGraphQLFields"
import { PlanSchedule } from "../../types/plan"

const numberFormat: Intl.NumberFormat = new Intl.NumberFormat("ru-RU", {
  style: "currency",
  currency: "RUB",
})

const cFormat = (value: number): string => numberFormat.format(value)

const columns: GridColumns = [
  {
    flex: 1,
    field: "plan.name",
    sortable: false,
    headerName: "name",
    renderCell: (params: GridCellParams): JSX.Element => params.row.plan.name,
  },
  {
    flex: 1,
    field: "details.payment_system",
    sortable: false,
    headerName: "ps",
    renderCell: (params: GridCellParams): JSX.Element => params.row.details.payment_system,
  },
  userColumn({
    key: "user",
    field: "created_by",
    headerName: "created_by",
    sortable: false,
    filterable: true,
    type: "search",
  }),
  dateColumn({
    headerName: "create_at",
    field: "created_at",
  }),
  dateColumn({
    field: "processed_at",
    headerName: "processed_at",
  }),
  dateColumn({
    field: "expires_at",
    headerName: "expires_at",
  }),
  {
    field: "status",
    headerName: "status",
    width: 125,
    renderCell: (params: GridCellParams): JSX.Element => <Status value={params.value as OrderStatus} />,
  },
  {
    flex: 1,
    field: "amount",
    sortable: false,
    headerName: "amount",
    renderCell: (params: GridCellParams): JSX.Element => <>{cFormat((params.row.amount as number) / 1000)}</>,
  },
]

const OrdersPage: React.FC = () => {
  const match: match = useRouteMatch()
  const history: ReturnType<typeof useHistory> = useHistory()

  const onRowClick: GridOptions["onRowClick"] = (params: GridRowParams): void => {
    history.push(`${match.url}/${params.row.id}`)
  }

  return (
    <Grid container spacing={1}>
      <Grid item sm={9}>
        <Table columns={columns} query={LOAD_ORDERS} onRowClick={onRowClick} />
      </Grid>
      <Grid item sm={3}>
        <Route path={`${match.url}/:id`} component={OrderView} />
      </Grid>
    </Grid>
  )
}

export default OrdersPage

const OrderView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useLoadOrder({
    fetchPolicy: "no-cache",
    variables: {
      id,
    },
  })

  if (loading || !data || !data.order) {
    return null
  }

  return (
    <Card>
      <CardHeader
        avatar={<UserAvatar small={false} url={data.order.user.avatar_url} />}
        title={data.order.user.name}
        subheader={<DateDisplay value={data.order.created_at} />}
      />
      <CardContent>
        <Section
          title="Order"
          items={[
            { label: "id", value: data.order.id },
            { label: "amount", value: cFormat(data.order.amount / 1000) },
            { label: "status", value: <Status value={data.order.status} /> },
            { label: "expires_at", value: <DateDisplay value={data.order.expires_at} /> },
            { label: "processed_at", value: <DateDisplay value={data.order.processed_at} /> },
          ]}
        />
        <Section
          title="Details"
          items={removeGraphQLFields(Object.keys(data.order.details)).map<SectionItem>((key: string) => {
            let value: null | number | boolean | undefined | PaymentSystem | string = (data.order as NonNullable<Order>)
              .details[key as keyof OrderDetails]
            if (key === "price" || key === "discount") {
              value = cFormat((value as number) / 1000)
            }
            return {
              label: key,
              value: <>{value === null ? "" : (value as string | boolean | number).toString()}</>,
            }
          })}
        />
        <Section
          title="Plan"
          items={[
            { label: "name", value: data.order.plan.name },
            { label: "alias", value: data.order.plan.alias },
            { label: "description", value: data.order.plan.description },
            { label: "price", value: cFormat(data.order.plan.price) },
            { label: "schedule", value: PlanSchedule[data.order.plan.schedule] },
          ]}
        />
        {data.order.payload && (
          <Section
            title="Payload"
            items={Object.keys(data.order.payload).map<SectionItem>((key: string) => ({
              label: key,
              value: ((data.order as NonNullable<Order>).payload as NonNullable<OrderPayload>)[key] as string,
            }))}
          />
        )}
      </CardContent>
    </Card>
  )
}

type StatusProps = {
  value: OrderStatus
}

const Status: React.FC<StatusProps> = (props: StatusProps) => {
  const { value } = props

  if (value === OrderStatus.Processed) {
    return <Chip size="small" color="primary" label="Processed" icon={<DoneIcon />} />
  }

  if (value === OrderStatus.Error) {
    return <Chip size="small" label="Error" color="secondary" icon={<ErrorOutlineIcon />} />
  }

  if (value === OrderStatus.Processing) {
    return <Chip size="small" label="Processing" icon={<AccessTimeIcon />} />
  }

  if (value === OrderStatus.New) {
    return <Chip size="small" label="New" />
  }

  return <></>
}
