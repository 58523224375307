import { gql, QueryHookOptions } from "@apollo/client"
import { DocumentNode } from "graphql"
import { UserPlan } from "./../types/userPlan"
import useQueryTable, { UseQueryTable } from "./useQueryTable"

const LOAD_USER_PLANS: DocumentNode = gql`
  query UserPlans(
    $page: Int
    $perPage: Int
    $sortField: UserPlanSortFieldGQLType
    $sortOrder: SortOrderGQLType
    $filterUserId: UUID
  ) {
    userPlans(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filterUserId: $filterUserId
    ) {
      data {
        id
        user_id
        plan_id
        start_at
        finish_at
        deleted_at
        auto_renew
        plan {
          name
          alias
        }
      }
      meta {
        pagination {
          total_count
          page
          per_page
          last_page
        }
      }
    }
  }
`

export default function useLoadUserPlans(options: QueryHookOptions): UseQueryTable<"userPlans", UserPlan> {
  return useQueryTable(LOAD_USER_PLANS, options)
}
