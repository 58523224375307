import React from "react"
import MainLayout from "../components/layout/Main"
import { Redirect, Route, Switch } from "react-router-dom"
import UsersRouter from "./users/router"
import DashboardPage from "./dashboard"
import { AuthResponse } from "../hooks/useLogin"
import FeedbackRouter from "./feedback/router"
import ProjectsRouter from "./projects/router"
import BillingRouter from "./billing/router"

type Props = {
  auth: AuthResponse
}

function AppRouter(props: Props): JSX.Element {
  const { auth } = props

  return (
    <MainLayout auth={auth}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route exact path="/dashboard" component={DashboardPage} />
        <Route path="/users" component={UsersRouter} />
        <Route path="/feedback" component={FeedbackRouter} />
        <Route path="/projects" component={ProjectsRouter} />
        <Route path="/billing" component={BillingRouter} />
      </Switch>
    </MainLayout>
  )
}

export default AppRouter
