import { UserFeatureUsage } from "./../types/userFeatureUsage"
import { gql, QueryHookOptions } from "@apollo/client"
import { DocumentNode } from "graphql"
import useQueryTable, { UseQueryTable } from "./useQueryTable"

export const USER_FEATURE_USAGE: DocumentNode = gql`
  query UserFeatureUsage(
    $page: Int
    $perPage: Int
    $sortField: UserFeatureUsageSortFieldGQLType
    $sortOrder: SortOrderGQLType
    $filterFeature: FilterFeatureGQLType
    $filterProcessed: String
    $filterUser: String
  ) {
    userFeatureUsage(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filterFeature: $filterFeature
      filterProcessed: $filterProcessed
      filterUser: $filterUser
    ) {
      data {
        id
        user_id
        user_plan_id
        feature
        value
        entity_type
        entity_id
        created_at
        processed
        user {
          id
          name
          email
          avatar_url
          role
          created_at
          deleted_at
          profile {
            payload {
              name
              email
              phone
              company
              position
            }
          }
        }
      }
      meta {
        pagination {
          total_count
          page
          per_page
          last_page
        }
      }
    }
  }
`

export default function useUserFeatureUsage(
  options: QueryHookOptions,
): UseQueryTable<"userFeatureUsage", UserFeatureUsage> {
  return useQueryTable(USER_FEATURE_USAGE, options)
}
